import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import Logo from "../components/logo"
import AppleLogo from "../components/apple-logo"
import GooglePlayLogo from "../components/google-play-logo"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="dict" />
    <div>
      <div>
        <div
          style={{
            top: "40%",
            left: "50%",
            position: "absolute",
            transform: "translate(-50%, -50%)",
          }}
        >
          <span
            style={{
              fontFamily: "Futura",
              color: "#222222",
              fontWeight: "bold",
              fontSize: "7em",
            }}
          >
            dict
          </span>
        </div>
      </div>
      <div
        style={{
          position: "fixed",
          width: "100%",
          alignItems: "center",
          bottom: "60px",
          left: 0,
          display: "grid",
          rowGap: "30px",
        }}
      >
        <div
          style={{
            justifySelf: "center",
            fontSize: "12px",
            display: "flex",
            gridRow: 1,
          }}
        >
          <a
            href="https://apps.apple.com/us/app/dict-%E9%9F%B3%E8%AA%AD-%E3%82%B7%E3%83%A3%E3%83%89%E3%83%BC%E3%82%A4%E3%83%B3%E3%82%B0%E3%81%AE%E3%81%9F%E3%82%81%E3%81%AE%E3%82%A2%E3%83%97%E3%83%AA/id1415273372?l=ja&ls=1"
            style={{
              border: "2px solid #222",
              borderRadius: "7px",
              width: "170px",
              height: "50px",
              display: "flex",
              marginLeft: "10px",
              marginRight: "5px",
              textDecoration: "none",
              backgroundColor: "#ffffff",
            }}
          >
            <AppleLogo />
            <span
              style={{
                width: "100px",
                height: "20px",
                marginTop: "12px",
                fontFamily: "Futura",
                color: "#222222",
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              App Store
            </span>
          </a>
          {false && (
            <a
              href="#"
              style={{
                border: "2px solid #222",
                borderRadius: "7px",
                width: "170px",
                height: "50px",
                display: "flex",
                marginLeft: "5px",
                marginRight: "10px",
                textDecoration: "none",
                backgroundColor: "#ffffff",
              }}
            >
              <GooglePlayLogo />
              <span
                style={{
                  width: "100px",
                  height: "20px",
                  marginTop: "12px",
                  fontFamily: "Futura",
                  color: "#bbbbbb",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "14px",
                }}
              >
                Google Play
              </span>
            </a>
          )}
        </div>
        <p
          style={{
            gridRow: 2,
            justifySelf: "center",
            fontSize: 12,
            color: "#222222",
          }}
        >
          <Link
            to="/privacy_policy"
            style={{ textDecoration: "none", color: "#222222" }}
          >
            Privacy Policy
          </Link>
        </p>
      </div>
    </div>
    <footer
      style={{
        position: "fixed",
        bottom: 0,
        left: "50%",
        textAlign: "center",
        transform: "translate(-50%, -50%)",
      }}
    >
      <br />© {new Date().getFullYear()} {` Kohei Abe`}
    </footer>
  </Layout>
)

const styles = {
  logoWrapper: {
    width: "100%",
    height: "auto",
    textAlign: "center",
  },
}

export default IndexPage
