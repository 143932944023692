import React from "react"

const AppleLogo = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginLeft: "12px", marginTop: "10px", marginRight: "12px" }}
  >
    <path
      d="M90.523 73.4443C90.2539 74.2039 89.9933 75.0196 89.6933 75.8213C87.2099 82.5457 83.4398 88.5161 78.8653 93.9876C77.9824 95.0527 76.9537 96.0169 75.8913 96.9111C73.7919 98.6882 71.3757 99.7617 68.5811 99.8599C66.4817 99.9271 64.4663 99.5459 62.5238 98.7555C61.1167 98.1809 59.732 97.539 58.3053 97.0204C53.6747 95.3386 49.0694 95.56 44.5145 97.3596C42.872 98.0099 41.2518 98.7274 39.5784 99.2908C37.6387 99.9411 35.6262 100.205 33.5772 99.8178C31.7889 99.4843 30.2248 98.6518 28.7728 97.5782C26.9705 96.244 25.4569 94.6182 24.0414 92.8972C17.3394 84.7236 12.8406 75.4821 10.6682 65.1306C9.73204 60.6795 9.28356 56.1918 9.55825 51.6426C9.8806 46.1879 11.1476 40.9939 14.0627 36.3129C17.7206 30.4294 22.8809 26.5753 29.6698 24.986C33.7538 24.0246 37.7256 24.417 41.619 25.9166C43.668 26.7014 45.7226 27.4779 47.78 28.2487C49.6748 28.9606 51.5613 28.9606 53.4589 28.2347C55.5584 27.433 57.6606 26.6342 59.7685 25.8521C61.9296 25.0645 64.1271 24.4198 66.42 24.2376C69.8509 23.9601 73.1977 24.4058 76.4547 25.4961C81.0713 27.0406 84.8273 29.7735 87.664 33.7538C87.7396 33.8631 87.8237 33.9836 87.8798 34.0705C80.7265 39.0571 76.6538 45.6441 77.2452 54.5633C77.8422 63.4881 82.6466 69.5257 90.523 73.4443ZM50.398 23.1332C52.5087 23.1893 54.5381 22.7828 56.4917 22.0064C65.4025 18.469 70.0611 9.04249 69.9209 1.83317C69.9097 1.25294 69.8705 0.672721 69.8453 0C68.9287 0.137347 68.0934 0.201816 67.2917 0.403633C60.786 1.99854 55.7798 5.62843 52.4891 11.5063C50.5634 14.9484 49.4926 18.6035 49.8206 22.5978C49.843 23.0211 49.9972 23.1276 50.398 23.1332Z"
      fill="black"
    />
  </svg>
)

export default AppleLogo
